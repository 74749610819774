import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import TSGOC_LOGO_F_8 from "../assets/logos/SHUBHAM GROUP-LastLogo.png";

function Header() {
  const [toggle, setToggle] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when route changes
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (toggle === true) {
        document.body.style.overflow = 'hidden';
        return;
      } else {
        document.body.style.overflow = 'auto';
        const currentScrollPos = window.scrollY;
        setScrolling(currentScrollPos > 0);

        // Make header visible when at the very top
        if (currentScrollPos <= 0) {
          setVisible(true);
          setPrevScrollPos(currentScrollPos);
          return;
        }

        // Determine scroll direction and update visibility
        const isScrollingDown = prevScrollPos < currentScrollPos;
        setVisible(!isScrollingDown);
        setPrevScrollPos(currentScrollPos);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.style.overflow = 'auto'; // Ensure body overflow is reset when component unmounts
    };
  }, [prevScrollPos, toggle]);

  // Toggle menu handler with body overflow control
  const handleToggle = () => {
    const newToggleState = !toggle;
    setToggle(newToggleState);
    document.body.style.overflow = newToggleState ? 'hidden' : 'auto';
  };

  return (
    <header
      className={`fixed w-full md:top-3 top-0 cel2 mx-auto z-50 px-[30px] transition-all duration-300 ease-in-out transform ${!visible ? "-translate-y-[120%]" : "translate-y-0"
        }`}
    >
      {/* Background layer with blur */}
      <div className="absolute inset-0 bg-white/40 md:backdrop-blur-0 backdrop-blur-sm md:bg-transparent z-0" />

      <div className="relative w-full flex md:px-[20px] rounded-xl justify-between md:bg-white/20 md:backdrop-blur-sm items-center py-5 mx-auto z-10">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            className="w-36 md:w-50 pb-1 rounded-full transition-transform duration-300 hover:scale-105"
            src={TSGOC_LOGO_F_8}
            alt="logo"
          />
        </Link>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden">
          {!toggle && (
            <AiOutlineMenu
              onClick={handleToggle}
              className="text-3xl cursor-pointer transition-transform transform hover:scale-110"
            />
          )}
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-8 text-xl font-semibold">
          <Link
            to="/"
            className="transition-transform transform hover:scale-110 hover:text-red-600"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="transition-transform transform hover:scale-110 hover:text-red-600"
          >
            About Us
          </Link>
          <Link
            to="/our-projects"
            className="transition-transform transform hover:scale-110 hover:text-red-600"
          >
            Our Projects
          </Link>
          <Link
            to="/contact"
            className="transition-transform transform hover:scale-110 hover:text-red-600"
          >
            Reach Us
          </Link>
          <Link
            to="/blogs"
            className="transition-transform transform hover:scale-110 hover:text-red-600"
          >
            Blogs
          </Link>
        </nav>


      </div>
      {/* Mobile Navigation */}
      <nav
        className={`fixed w-full left-0 h-[100vh] bg-white bg-opacity-90 top-0 flex flex-col items-center justify-center space-y-10 text-black text-2xl md:hidden transition-all duration-500 transform ${toggle ? "translate-x-0" : "-translate-x-full"
          }`}
        style={{ zIndex: 1000 }}
      >
        {toggle && (
          <AiOutlineClose
            onClick={handleToggle}
            className="absolute top-5 right-5 text-3xl cursor-pointer"
          />
        )}
        <Link onClick={handleToggle} to="/" className="p-5 hover:text-red-600">
          Home
        </Link>
        <Link onClick={handleToggle} to="/about" className="p-5 hover:text-red-600">
          About Us
        </Link>
        <Link
          onClick={handleToggle}
          to="/our-projects"
          className="p-5 hover:text-red-600"
        >
          Our Projects
        </Link>
        <Link onClick={handleToggle} to="/contact" className="p-5 hover:text-red-600">
          Reach Us
        </Link>
        <Link onClick={handleToggle} to="/blogs" className="p-5 hover:text-red-600">
          Blogs
        </Link>
      </nav>
    </header>
  );
}

export default Header;