import React from "react";
import line2 from "../assets/1.png"

function MainHeading() {
  return (
    <div
      className="relative mx-auto flex flex-col justify-center w-fit mt-[26px] md:mt-[12px] lg:mt-[12px]"

    >
      <p
        className="text-3xl cel md:text-5xl uppercase lg:text-6xl mx-auto text-center bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent font-bold"
        style={{
          marginTop: "12px",
        }}
      >
        The Shubham Group
      </p>
      <img src={line2} alt="" className=" w-[400px] mx-auto" />
      <p
        className="text-sm arvo md:text-3xl font-semibold mx-auto text-center mb-0 pb-0 px-2 text-gray-600"
      >
        Real Estate, Construction, Resorts, Collaboration and Banquets
      </p>
      <h1 className="text-5xl md:text-6xl lg:text-8xl cour mt-8 mx-auto text-center bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent overflow-visible">
        Your Second Home Company
      </h1>
    </div>
  );
}

export default MainHeading;
