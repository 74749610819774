import React from "react";
import { Container } from "@mui/material";
import CommonCard from "../card";
import config from "../../config";
import line from "../../assets/1.png";
import { motion } from "framer-motion";
import indus from "../../assets/changeimages/indus.png"
import chv from "../../assets/changeimages/chv.png"

function Project1_Residential() {

  const projects = [
    {
      title: "Indus Valley",
      location: "Mukteshwar, Nainital",
      imgSrc: indus,
      link: "https://www.indusvalleymukteshwar.com",
    },
    // {
    //   title: "Queens Land",
    //   location: "Ranikhet",
    //   imgSrc: `${config.S3_URL}/queensland.png`,
    //   link: "https://indusvalleyranikhet.com/",
    // },
    {
      title: "Chail Heights Villas",
      location: "Chail, Himachal Pradesh",
      imgSrc: chv,
      link: "http://www.chailheightsvillas.com",
    },
  ];

  return (
    <div className="mt-12">
      <Container>
        <section className="flex flex-col justify-center gap-5 lg:gap-16 p-3">
          <motion.div
            className="text-3xl text-center cel2 mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent"
            whileInView={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 100 }}
            transition={{ duration: 1 }}
          >
            Ongoing Residential Projects
            <img src={line} alt="" className="mx-auto h-4 mt-3" />
          </motion.div>
        </section>
        <div className="flex flex-col gap-0">
          <section className="grid sm:grid-cols-1 md:grid-cols-2 gap-10 m-8 -mt-3 md:m-auto p-3">
            {projects.map((project, index) => (
              <motion.div
                className="w-full"
                key={index}
                whileInView={{ opacity: 1, scale: 1 }}
                initial={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.5 }}
              >
                <CommonCard
                  title={project.title}
                  location={project.location}
                  imgSrc={project.imgSrc}
                  link={project.link}
                />
              </motion.div>
            ))}
          </section>
        </div>
      </Container>
    </div>
  );
}

export default Project1_Residential;
