import React, { useState } from 'react'

function About() {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className='container mx-auto'>
      <div className='max-w-6xl mx-auto arvo text-md md:text-xl mt-8 md:mt-0 text-justify px-5 text-gray-600' >

        <p className="mb-3">
          At the Shubham Group, we create more than just homes- we create a lifestyle. What started as a vision in 2009 to provide the highest quality tranquil retreats in the lap of the majestic Himalayas, soon transformed into a real estate powerhouse that has a portfolio of residential, commercial, and hospitality projects.
        </p>

        <p className='md:block hidden'>
          With over 16 years of experience, we are headquartered in Delhi and operate out of our corporate office in Noida. The Shubham Group has successfully launched and delivered several of its projects in the most scenic hill stations, with its signature projects being Indus Valley in Mukteshwar and Chail Heights Villas in Chail. These stand as testaments to our mission to blend luxury with a peaceful lifestyle, an escape from the bustling city life. At TSG, we offer a range of real estate solutions – from ready-to-move-in cottages and villas in the Himalayas to custom-built homes designed to meet the unique preferences of our clients. With all of this, our expertise also extends to property construction and collaboration across Delhi NCR under the brand name Shape My House.
          At The Shubham Group, our aim is to redefine modern living by offering spaces that blend with the mother nature.
        </p>
        {!showMore &&
          <p className='text-red-600 block md:hidden text-center' onClick={() => setShowMore(!showMore)}>Read More...</p>
        }
        {showMore &&
          <p className='block md:hidden'>
            With over 16 years of experience, we are headquartered in Delhi and operate out of our corporate office in Noida. The Shubham Group has successfully launched and delivered several of its projects in the most scenic hill stations, with its signature projects being Indus Valley in Mukteshwar and Chail Heights Villas in Chail. These stand as testaments to our mission to blend luxury with a peaceful lifestyle, an escape from the bustling city life. At TSG, we offer a range of real estate solutions – from ready-to-move-in cottages and villas in the Himalayas to custom-built homes designed to meet the unique preferences of our clients. With all of this, our expertise also extends to property construction and collaboration across Delhi NCR under the brand name Shape My House.
            At The Shubham Group, our aim is to redefine modern living by offering spaces that blend with the mother nature.
          </p>
        }
      </div>

    </div>
  )
}

export default About