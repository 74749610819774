import React, { useEffect, useRef } from "react";
import About from "../../newcomponents/home/About";
import MainHeading from "../MainHeading";
import img from "../../assets/walloffafme.png"
import OurProjects from "./Our_Projects";
import AOS from "aos";
import "aos/dist/aos.css";
import { Box } from "@mui/material";
import SimpleCarousel from "../../newcomponents/home/SimpleCarousel";
import About2 from "../../newcomponents/home/About2";

function Home() {
  const containerRef = useRef(null);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div
      className=""
      ref={containerRef}
      data-aos-anchor-placement="top-center"
      data-aos="fade-up"
    >
      <SimpleCarousel/>
      <Box className="bg-contain bg-center flex flex-col md:gap-10 md:p-5 pt-0 w-full">
        <MainHeading />
        <About/>
        <OurProjects />
        <img
          data-scroll
          data-scroll-section
          data-scroll-speed=".1"
          className="w-full"
          src={img}
          alt="TSGOC"
        />
      </Box>
    </div>
  );
}

export default Home;
