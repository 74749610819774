import React from "react";
import { motion } from "framer-motion";
import CommonCard from "../card";
import images from "../../assets/changeimages/aavdam.png"
import line from "../../assets/1.png"

const projects = [
  {
    id: 1,
    title: "Aavedam Banquets",
    location: "Delhi NCR",
    imgSrc: images,
    link: "https://aavedamhospitality.com/",
  },
];

function ProjectBenquet() {
  return (
    <motion.div
      whileInView={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 100 }}
      transition={{ duration: 1 }}
      className="text-3xl text-center cel2 mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent"
    >
      Banquets
      <img src={line} alt="" className=" mx-auto h-4 mt-3 mb-5" />

      {/* (Project Component-2) */}
      <div className="flex flex-col">
        <section className="grid gap-5 md:m-auto p-3 md:gap-20 m-12 -mt-3">
          {projects.map((project) => (
            <div className="w-full" key={project.id}>
              <CommonCard
                title={project.title}
                location={project.location}
                imgSrc={project.imgSrc}
                link={project.link}
              />
            </div>
          ))}
        </section>
      </div>
    </motion.div>
  );
}

export default ProjectBenquet;
