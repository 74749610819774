import React from "react";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { commonStyle } from "../../constants/constant";
import line from "../../assets/1.png";

function About() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      <div
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
        className="flex mt-[100px] flex-col gap-5 bg-cover bg-center"
        style={commonStyle}
      >
        <p
          className="w-fit cel mx-auto text-3xl md:text-5xl mt-12 bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent font-bold text-center "
        >
          About Us
          <img src={line} alt="" className=" h-4 mt-2" />

        </p>
        <div className="flex flex-col gap-4 justify-center items-center">
          <div className="flex flex-col items-center gap-3">
            <div
              className="text-md md:text-lg arvo md:mx-10 px-1 mb-8 text-gray-600 max-w-6xl mx-auto text-justify"
            >
              <p className="mb-3">At the Shubham Group, we create more than just homes- we create a lifestyle. What started as a vision in 2009 to provide the highest quality tranquil retreats in the lap of the majestic Himalayas, soon transformed into a real estate powerhouse that has a portfolio of residential, commercial, and hospitality projects.</p>

              With over 16 years of experience, we are headquartered in Delhi and operate out of our corporate office in Noida. The Shubham Group has successfully launched and delivered several of its projects in the most scenic hill stations, with its signature projects being Indus Valley in Mukteshwar and Chail Heights Villas in Chail. These stand as testaments to our mission to blend luxury with a peaceful lifestyle, an escape from the bustling city life. At TSG, we offer a range of real estate solutions – from ready-to-move-in cottages and villas in the Himalayas to custom-built homes designed to meet the unique preferences of our clients. With all of this, our expertise also extends to property construction and collaboration across Delhi NCR under the brand name Shape My House.
              At The Shubham Group, our aim is to redefine modern living by offering spaces that blend with the mother nature.
              
            </div>
            <div className="flex flex-col justify-center mx-auto text-center gap-5 md:gap-10 pb-10">
              <p
                className="w-fit cel mx-auto text-3xl md:text-5xl bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent font-bold text-center "
              >
                Startup Journey
                <img src={line} alt="" className=" h-6 mt-5" />

              </p>
              <p
                className="text-md md:text-lg arvo md:mx-10 px-1 text-gray-600 max-w-6xl mx-auto text-justify"
              >
                The Shubham Group was started by Mr Agam Aggarwal in 2009 with a
                vision of creating value in real estate. At the time when the
                company started, there was some void in the market of the
                concept of second homes on hill stations for people living in
                big metropolis like Delhi NCR. Some of these individuals had a
                strong desire of often travelling to mountains and wished to
                have a house in the lap of Himalayas. This would make it
                possible for them to spend their leisure time in complete peace
                and beauty of nature. This is why the company launched its first
                residential project near Nainital and till now has delivered 2
                such projects in Uttarakhand and 1 in Himachal Pradesh with 100%
                happy and satisfied customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
