import React from 'react'

function ShimlaDelivered() {
  return (
    <div className='z-50 top-0 left-0 fixed h-[100vh] w-full'>
        <img src="https://d2mx3e50f47l1n.cloudfront.net/Deliverd-Banner-Shimla.jpg" className='w-full h-full object-cover object-center' alt="" />
    </div>
  )
}

export default ShimlaDelivered