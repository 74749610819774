import React from 'react'
import TSGOC_LOGO_F_8 from "../assets/logos/SHUBHAM GROUP-LastLogo.png";
import icon_facebook from "../components/screens/icons2/icon_facebook.png";
import icon_twitter from "../components/screens/icons2/icon_twitter.png";
import icon_insta from "../components/screens/icons2/icon_insta.png";
import icon_yt from "../components/screens/icons2/icon_yt.png";
import icon_linkedin from "../components/screens/icons2/icon_linkedin.png";

function Footer() {
    return (
        <div className=' container mx-auto mt-12'>
            <div className='w-full flex border-b-2 mb-4 pb-4 justify-center items-center'>
                <div
                    className="gap-5 md:gap-6 flex"
                >
                    {[
                        [icon_facebook, "Facebook Link..", "https://www.facebook.com/theshubhamGroup/"], [icon_twitter, "Twitter Link..", "https://twitter.com/theshubhamgroup"], [icon_insta, "Instagram Link..", "https://www.instagram.com/theshubhamgroup/"], [icon_yt, "Youtube Link..", "https://www.youtube.com/@indusvalleymukteshwar"], [icon_linkedin, "Linkedin Link..", "https://www.linkedin.com/company/the-shubham-group1/"]].map((e) => (
                            <a href={e[2]} rel="noreferrer" target="_blank">
                                <img
                                    className="w-8 md:w-12 md:p-1 md:rounded-lg animate__zoomIn2 "
                                    src={e[0]}
                                    alt={e[1]}
                                />
                            </a>
                        ))}
                </div>
            </div>

            <div className='w-full grid grid-cols-1 md:grid-cols-4 gap-8 justify-center items-end mb-12  p-4'>
                <div className='w-full flex flex-col gap-5 items-center justify-center'>
                    <img src={TSGOC_LOGO_F_8} alt='TSGOC' className='md:h-36 h-32 object-contain' />
                </div>
                <div className='w-full'>
                    <p className='text-lg md:text-2xl font-bold text-gray-800 mb-3 md:text-start cel text-center'>Registered Office</p>

                    <address className='text-gray-600 md:text-xl text-md md:text-start arvo text-center not-italic'>
                        The Shubham Group,  A-24, <br /> Okhla Industrial Area, Phase-2, <br /> Delhi, Pincode - 110020
                    </address>
                </div>
                <div className='w-full'>
                    <p className='text-lg md:text-2xl  font-bold text-gray-800 mb-3 md:text-start cel text-center'>Corporate Address</p>
                    <a href='https://maps.app.goo.gl/dNzTgKr58UaUHy767' target='_blank' rel='noreferrer'>
                        <address className='text-gray-600 md:text-xl text-md hover:text-red-700 text-md md:text-start arvo text-center not-italic'>
                            B - 315, <br /> Sector-105 Noida, <br /> Uttar Pradesh, Pincode - 201304
                        </address>
                    </a>
                </div>
                <div className='w-full'>
                    <p className='text-lg md:text-2xl font-bold text-gray-800 mb-3 md:text-start cel text-center'>Contact Us</p>

                    <a
                        href="mailto:info@theshubhamgroup.com"
                        className="block arvo md:text-xl text-md text-center md:text-start hover:text-red-700"
                    >
                        info@theshubhamgroup.com
                    </a>
                    <a
                        href="tel:+918510850101"
                        className="block arvo md:text-xl text-md text-center md:text-start hover:text-red-700"
                    >
                        85 10 85 01 01
                    </a>
                </div>
            </div>

            <p className="text-xs text-center py-4">
                © {new Date().getFullYear()} The Shubham Group. All rights reserved.
            </p>
        </div>
    )
}

export default Footer