import React from "react";
import { motion } from "framer-motion";
import CommonCard from "../card";
import line from "../../assets/1.png"
import homestay from "../../assets/changeimages/homestay.png"

const homestays = [
  {
    id: 1,
    title: "Indus Valley Homestay",
    location: "Mukteshwar, Nainital",
    imgSrc: homestay,
    link: "https://www.indusvalleyhomestay.com",
  },
];

function Project2_1_Homestay() {
  return (
    <motion.div
      whileInView={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 100 }}
      transition={{ duration: 1 }}
      className="text-3xl text-center cel2 md:mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent"
    >
      Homestay
      <img src={line} alt="" className=" mx-auto h-4 mt-3 mb-5" />

      <div className="flex flex-col">
        <section className="grid gap-5 m-12 mt-4 md:m-auto p-3 md:gap-20">
          {homestays.map((homestay) => (
            <div className="w-full" key={homestay.id}>
              <CommonCard
                title={homestay.title}
                location={homestay.location}
                imgSrc={homestay.imgSrc}
                link={homestay.link}
              />
            </div>
          ))}
        </section>
      </div>
    </motion.div>
  );
}

export default Project2_1_Homestay;
