import React, { useState } from "react";
import { motion } from "framer-motion";
import CommonCard from "../card";
import config from "../../config";
import line from "../../assets/1.png"
import ambika from "../../assets/changeimages/ambika.png"
import shimla from "../../assets/changeimages/shimla.png"

function ProjectDelivered() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openFullscreenImage = (image) => {
    setSelectedImage(image);
  };

  const closeFullscreenImage = () => {
    setSelectedImage(null);
  };

  const projects = [
    {
      id: 1,
      title: "Ambika Suites",
      location: "Shantikunj, Haridwar",
      imgSrc: ambika,
      link: "https://ambika.tsgoc.in/",
    },
    {
      id: 2,
      title: "Indus Valley Apartments",
      location: "Shimla, Himachal Pradesh",
      imgSrc: shimla,
      link:"https://shimla.tsgoc.in/",
    },
  ];

  return (
    <motion.div
      whileInView={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 100 }}
      transition={{ duration: 1 }}
      className="text-3xl text-center cel2 md:mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent"
    >
      Delivered Projects
      <img src={line} alt="" className=" mx-auto h-4 mt-3 mb-5" />
      <div className="flex flex-col gap-0">
        <section className="grid sm:grid-cols-1 md:grid-cols-2 gap-10 m-12 mt-4 md:m-auto p-3 ">
          {projects.map((project) => (
            <div className="w-full cursor-pointer" key={project.id}>
              <CommonCard
                title={project.title}
                location={project.location}
                imgSrc={project.imgSrc}
                link={project.link}
                onClick={project.onClick}
              />
            </div>
          ))}
        </section>
      </div>

      {/* Fullscreen Image */}
      {selectedImage && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 z-50 flex justify-center items-center"
          onClick={closeFullscreenImage}
        >
          <div className="max-w-full max-h-full p-4">
            <img
              src={selectedImage}
              alt="Fullscreen"
              className="max-w-full max-h-full cursor-pointer"
            />
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default ProjectDelivered;
