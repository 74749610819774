import React, { useState } from "react";
// import bg2 from "./Background_img/bg2.jpg";
import gal_img1 from "./gallery_img/gal_img1.jpg";
import gal_img2 from "./gallery_img/gal_img2.jpg";
import gal_img3 from "./gallery_img/gal_img3.jpg";
import gal_img4 from "./gallery_img/gal_img4.jpg";
import gal_img5 from "./gallery_img/gal_img5.jpg";
import gal_img6 from "./gallery_img/gal_img6.jpg";
import gal_img7 from "./gallery_img/gal_img7.jpg";
import gal_img8 from "./gallery_img/gal_img8.jpg";
import gal_img9 from "./gallery_img/gal_img9.jpg";
import gal_img10 from "./gallery_img/gal_img10.jpg";
import gal_img11 from "./gallery_img/gal_img11.jpg";
import gal_img12 from "./gallery_img/gal_img12.jpg";
const images = [
  gal_img1,
  gal_img2,
  gal_img3,
  gal_img4,
  gal_img5,
  gal_img6,
  gal_img7,
  gal_img8,
  gal_img9,
  gal_img10,
  gal_img11,
  gal_img12,

  // ... add more paths as needed
];

function Gallery() {
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  // Back button handler
  const goBackToGallery = () => {
    setFullscreenImage(null);
  };

  // Next button handler
  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    console.log("Next Index:", nextIndex);
    console.log("Next Image:", images[nextIndex]);

    setCurrentIndex(nextIndex);
    openFullscreen(images[nextIndex], nextIndex);
  };

  return (
    <div className="bg-[#E0EED4] bg-opacity-70">
      <h2 className="text-3xl md:text-5xl text-center py-8">GALLERY</h2>
      <div
        // style={{ backgroundImage: `url(${bg2})` }}
        className="grid grid-cols-1 md:grid-cols-4 gap-10 p-5 md:p-24 md:pt-10 bg-cover mx-auto"
      >
        {images.map((image, index) => (
          <img
            key={index}
            className=" lg:hover:shadow-xl lg:hover:shadow-white shadow-2xl shadow-black gap-5 rounded-xl md:hover:scale-110 md:duration-700 cursor-pointer"
            src={image}
            alt={`Gallery ${index + 1}`}
            onClick={() => openFullscreen(image)}
          />
        ))}
      </div>

      {fullscreenImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center">
          <button
            className="absolute bottom-28 left-20 text-Black bg-white font-semibold text-sm md:text-xl m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none
            "
            onClick={goBackToGallery}
          >
            Back
          </button>

          <button
            className="absolute bottom-28 right-20 text-Black bg-white font-semibold text-sm md:text-xl m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            onClick={showNextImage}
          >
            Next
          </button>
          <img
            src={fullscreenImage}
            alt="Fullscreen"
            className="max-h-full max-w-full cursor-pointer p-3 md:p-20 md:pt-32"
            onClick={closeFullscreen}
          />
        </div>
      )}
    </div>
  );
}

export default Gallery;
