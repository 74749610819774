import React from "react";
import Project1Residential from "./Project1_Residential";
import Project2Resorts from "./Project2_Resorts";
import Project3Trading from "./Project3_Trading";
import ProjectDelivered from "./ProjectDelivered";
import ProjectHomestay from "./Project2_1_Homestay";
import ProjectUpcomingResidential from "./ProjectUpcomingResidential";
import ProjectBenquet from "./ProjectBenquet";
import line from "../../assets/1.png";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";


function Our_Projects() {

  const location = useLocation()

  return (
    <motion.div
      whileInView={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 50 }}
      transition={{ duration: 1 }}
      className="your-class-name"
    >
      <p
        className={`text-3xl ${location.pathname === "/our-projects" ? "mt-[170px]" : "md:mt-2"} mt-12 mx-3 flex flex-col items-center md:text-5xl md:pt-5 bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent  uppercase font-bold text-center cel`}
      >
        Explore Our Projects
        <img src={line} alt="" className=" h-5 mt-3" />
      </p>

      <Project1Residential />
      <div className="flex flex-col md:gap-10">
        <ProjectUpcomingResidential />
        <Project2Resorts />
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:gap-16 md:grid-cols-2 lg:grid-cols-3 justify-center">
          <ProjectHomestay />
          <ProjectBenquet />
          <Project3Trading />
        </div>
        <ProjectDelivered />
      </div>
    </motion.div>
  );
}

export default Our_Projects;
