import React from "react";
import line from "../../assets/1.png"
import blog3 from "../../assets/blog3.png"



const Blog3 = () => {
  return (
    <div className="flex flex-col mt-[100px] cel2 justify-center items-center bg-white p-8">
      <img
        className="p-3 md:p-5 xl:p-10"
        src={blog3}
        alt="title"
      />
      <div className="w-full max-w-6xl  my-10 flex flex-col gap-5">
        <div className="text-2xl md:text-4xl cel2 font-bold bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent text-center">
          <p>Collaboration and construction expansion the Shubham Group
          diversification</p>
      <img src={line} alt="" className=" mx-auto mt-3 h-6"/>

        </div>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          Since the inception of <strong>The Shubham Group</strong> in 2009, it
          has grown into a multifaceted real estate organization that has
          followed and delivered on the mission and vision of transforming
          dreams into a reality. With a strong foothold in Delhi NCR through its
          Shape My House initiative, the group has expanded its services from
          hill station properties to major collaborations and civil
          constructions across several parts of India. As a prominent layer of
          Delhi NCR real estate market, With each project, The Shubham Group
          offers a unique blend of innovation, expertise, and above all a
          customer-centric approach.
        </p>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          Let us take you to the journey of The Shubham Group’s collaboration
          services and construction expansion, and give you deep insights about
          its mission to deliver high-quality, customized homes through{" "}
          <strong>Shape My House.</strong> The group has successfully ventured
          into <strong>Property Collaboration</strong> and construction services
          in all of Delhi NCR. Let's now deep dive into understanding how these
          operations are shaping the Delhi NCR future housing.
        </p>
        <h2 className="text-lg md:text-3xl bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent font-semibold pt-5 text-center bg-cover">
          Property Collaboration: Building Homes The Smart Way
        </h2>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          Property collaboration is rapidly gaining demand in the ever-dense
          urban spaces of Delhi NCR, and <strong>The Shubham Group</strong> has
          capitalized on this traction by offering a unique proposition to the
          homeowners. Through
          <strong> Property Collaboration in Delhi</strong> and{" "}
          <strong>Property Collaboration in Noida,</strong>Shape My House allow
          homeowners to partner with the company to build modern homes on their
          existing plots without investing their own capital. The company
          construct homes at its own expense, in exchange for owning one or more
          floors of the property, dependin on the needs and requirements of the
          clients.
        </p>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          This model is beneficial in metropolitan cities like Delhi, where real
          estate development can be a significantly challenging and expensive
          task. The unique selling proposition of this particular service lies
          in its <strong>hassle-free process</strong>—from internal and external
          architectural design to construction and all the necessary approvals,
          The Shubham Group ensures that all aspects of property development are
          taken care of with complete professionalism and integrity. We promise
          to deliver a peace of mind to all the homeowners. We give them this
          surety that their <strong>Developer in Delhi</strong> is handling the
          project with utmost professionalism and expertise.
        </p>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          Shape My House offers these collaboration services in all the parts of
          Delhi NCR, including Delhi,
          <strong>Noida, Greater Noida, Gurgaon,</strong> ensuring a wide reach
          off the clientele. This collaboration model comes without any
          financial burden and is perfect for those who wish to construct a new
          home and are looking for a{" "}
          <strong>Construction Company in Delhi NCR </strong>that guarantees
          quality and transparency.
        </p>
        <p className="text-lg bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent md:text-3xl font-semibold pt-5 text-center bg-cover">
          Comprehensive Construction Services: Building Dreams, One House At A
          Time
        </p>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          Property collaboration provides a cost-efficient solution for plot
          owners, however,<strong> Shape My House </strong> even takes it one
          step forward by offering top-notch construction services for clients
          who wish to have a completely customized home. If you are looking for
          a <strong>Construction Company in Gurgaon,</strong> Noida or Delhi,
          Shape My House is a one stop shop that provides the best civil
          contracting services that delivers on the highest possible quality.
        </p>
        <p className="text-lg bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent md:text-3xl font-semibold pt-5 text-left bg-cover">
          Key Features of Shape My House's Construction Services
        </p>
        <ol className="list-decimal flex flex-col gap-5">
          <li className ="text-lg md:text-xl">
            <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
              <strong>Systematic Approach:</strong> The company employs several
              strategic and systematic approaches that help transform client’s
              dreams into dynamic construction and architectural plans and
              further into reality. With this approach we ensure that every
              minute detail is accounted for.
            </p>
          </li>
          <li className="text-lg md:text-xl">
            <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
              <strong>Experienced Workforce:</strong> With the top-class
              professionals including site managers, architects, construction
              experts, the company ensures that each project is completed and
              delivered with utmost professionalism and integrity.
            </p>
          </li>
          <li className="text-lg md:text-xl">
            <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
              <strong>Comprehensive Project Management:</strong> With the
              end-to-end management of each project, the company ensures that
              all of its clients are well informed throughout the entire
              process. The rigorous on-site inspections to detailed reporting,
              the company follows strict guidelines to deliver on its promise.
            </p>
          </li>
          <li className="text-lg md:text-xl">
            <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
              <strong>Customized Solutions:</strong> Shape My House constructs
              homes that reflect the unique personality and needs of each of its
              client. We ensure that no other project is same and is only suited
              to the individual preferences.
            </p>
          </li>
        </ol>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          One of the unique selling propositions by{" "}
          <strong>Shape My House</strong> is its guarantee of highest quality
          and on-time delivery. The Shubham Group takes immense pride in its
          projects, as it follows its commitment on transparency, offering
          detailed project reports, and following strict guidelines of quality
          checks. This level of commitment Shape My House employs in its
          projects makes it a trusted{" "}
          <strong>Construction Company in Noida</strong>and other regions of
          NCR.
        </p>
        <p className="text-lg bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent md:text-3xl font-semibold pt-5 text-left bg-cover">
          Expanding Horizons
        </p>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          While <strong>Shape My House</strong> is transforming the experience
          of home-building in Delhi NCR, <strong>The Shubham Group </strong>has
          even extended its services even outside the city limits, by offering
          real estate projects in the picturesque and stunning locations like
          Mukteshwar, Haridwar, Shimla and Chail. For those seeking an escape
          from hustle bustle of city life, the Shubham Group's{" "}
          <strong>Best Projects in Uttarakhand</strong> offer a perfect nest
          amidst the nature’s lap.
        </p>
        <p className="text-lg bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent md:text-3xl font-semibold pt-5 text-left bg-cover">
          Some of the notable projects include:
        </p>
        <ol className="list-disc flex flex-col gap-5">
          <li className="text-lg md:text-xl">
            <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
              <strong>Indus Valley Mukteshwar:</strong> Nestled in the heart of
              nature, only a couple of hours away from Nainital and Bhimtal,
              this project is a haven for those looking for a second home in the
              lap of hills. It offers the most serene cottages and homestays,
              catering to individuals looking for a peaceful retreat.
            </p>
          </li>
          <li className="text-lg md:text-xl">
            <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
              <strong>Ambika Divinity Haridwar:</strong>This project offers
              luxury <strong>Apartments in Haridwar </strong>that is ideal for
              those seeking divine spiritual serenity combined with ultra-modern
              amenities.
            </p>
          </li>
          <li className="text-lg md:text-xl">
            <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
              <strong>Indus Valley Shimla: </strong> Another subsidiary under
              The Shubham Group’s umbrella, this project combines splendid homes
              with serene views of the Himalayas, making it one of the most
              sought-after <strong>Property in Himachal.</strong>
            </p>
          </li>
        </ol>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          The company’s upcoming real-estate projects, such as{" "}
          <strong> Neverland Resorts Mukteshwar </strong>and{" "}
          <strong>Chail Heights Resorts, </strong>further strengthens its
          commitment to providing high-quality real estate. By diversifying into
          the hospitality sector, The Shubham Group is constantly living up to
          its ambition to cater to a broader market, expanding from residential
          homes to luxurious resorts and homestays.
        </p>
      </div>
    </div>
  );
};

export default Blog3;
