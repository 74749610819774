import React from "react";
import { Container } from "@mui/material";
import CommonCard from "../card";
import indusoceanvalley from "../../assets/changeimages/indusoceanvalley.png"
import chamba from "../../assets/changeimages/indusvalleychamba.png"
import line from "../../assets/1.png"
import ranikhet from "../../assets/changeimages/indusvalleyranikhet.png"
import { motion } from "framer-motion";

function ProjectUpcomingResidential() {

  const projects = [
    {
      title: "Indus Valley Ranikhet",
      location: "Ranikhet, Uttarakhand",
      imgSrc: ranikhet,
      link: "https://indusvalleyranikhet.com/",
    },
    {
      title: "Indus valley Chamba",
      location: "Chamba, Uttarakhand",
      imgSrc: chamba,
      link: "https://indusvalleychamba.com/",
    },
    {
      title: "Indus Valley Vangurla",
      location: "Sindhudurg, Maharashtra",
      imgSrc: indusoceanvalley,
      link: "https://indusvalleyvengurla.com/",
    },
  ];

  return (
    <div className="mt-0 md:mt-12">
      <Container>
        <section className="flex flex-col justify-center gap-5 lg:gap-16 p-3">
          <motion.div
            className="text-3xl text-center cel2 md:mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent"
            initial={{ opacity: 0, y: 100 }} // Initial state for animation
            whileInView={{ opacity: 1, y: 0 }} // Animation when in view
            transition={{ duration: 1 }} // Animation duration
            viewport={{ once: true }} // Animation triggers only once
          >
            Upcoming Residential Projects
            <img src={line} alt="" className=" mx-auto h-4 mt-3" />
          </motion.div>
        </section>

        <div className="flex flex-col gap-0">
          <section className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 m-8 -mt-0 md:m-auto p-3 ">
            {projects.map((project, index) => (
              <motion.div
                className="w-full"
                key={index}
                initial={{ opacity: 0, y: 20 }} // Initial state for animation
                whileInView={{ opacity: 1, y: 0 }} // Animation when in view
                transition={{ duration: 0.5, delay: index * 0.1 }} // Animation duration with delay
                viewport={{ once: true }} // Animation triggers only once
              >
                <CommonCard
                  title={project.title}
                  location={project.location}
                  imgSrc={project.imgSrc}
                  link={project.link}
                />
              </motion.div>
            ))}
          </section>
        </div>
      </Container>
    </div>
  );
}

export default ProjectUpcomingResidential;
