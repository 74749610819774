import React from "react";
import { Container } from "@mui/material";
import CommonCard from "../card";
import config from "../../config";
import line from "../../assets/1.png";
import never from "../../assets/changeimages/neverland.png"
import { motion } from "framer-motion"; // Added Framer Motion import
import chr from "../../assets/changeimages/chr.png"

const resorts = [
  {
    id: 1,
    title: "Neverland Resort",
    location: "Mukteshwar, Nainital",
    imgSrc: never,
    link: "https://www.neverlandresorts.com",
  },
  {
    id: 2,
    title: "Chail Heights Resorts",
    location: "Chail, Himachal Pradesh",
    imgSrc: chr,
    link: "https://www.chailheightsresort.com",
  },
];

function Project2_Resorts() {
  return (
    <div>
      <Container>
        <section className="flex flex-col justify-center gap-5 lg:gap-16 p-3">
          <div
            className="text-3xl text-center cel2 md:mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent"
          >
            Upcoming Series Of Resorts
            <img src={line} alt="" className=" mx-auto h-4 mt-3" />
          </div>
        </section>

        {/* Resort Cards */}
        <div className="flex flex-col gap-0">
          <section className="grid sm:grid-cols-1 md:grid-cols-2 gap-10 m-8 mt-0 md:m-auto p-3 ">
            {resorts.map((resort, index) => (
              <motion.div
                className="w-full"
                key={index}
                whileInView={{ opacity: 1, y:0 }}
                initial={{ opacity: 0, y:100 }}
                transition={{ duration: 1 }}
              >
                <CommonCard
                  title={resort.title}
                  location={resort.location}
                  imgSrc={resort.imgSrc}
                  link={resort.link}
                />
              </motion.div>
            ))}
          </section>
        </div>
      </Container>
    </div>
  );
}

export default Project2_Resorts;
