import React from "react";
import { motion } from "framer-motion";
import CommonCard from "../card";
import config from "../../config";
import line from "../../assets/1.png"
import shapemyhouse from "../../assets/changeimages/shapemyhouse.png"

const projects = [
  {
    id: 1,
    title: "Shape My House",
    location: "Delhi NCR",
    imgSrc: shapemyhouse,
    link: "https://www.shapemyhouse.com",
  },
];

function Project3_Trading() {
  return (
    <motion.div
      whileInView={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 100 }}
      transition={{ duration: 1 }}
      className="text-2xl text-center cel2 mb-6 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent"
    >
      Construction & Collaboration
      <img src={line} alt="" className=" mx-auto h-4 mt-3 mb-5" />

      {/* (Project Component-2) */}
      <div className="flex flex-col">
        <section className="grid gap-5 m-12 -mt-2 md:m-auto p-3 md:gap-20">
          {projects.map((project) => (
            <div className="w-full" key={project.id}>
              <CommonCard
                title={project.title}
                location={project.location}
                imgSrc={project.imgSrc}
                link={project.link}
              />
            </div>
          ))}
        </section>
      </div>
    </motion.div>
  );
}

export default Project3_Trading;
