import React from 'react'
import { motion } from 'framer-motion'
// import { MapPin, Phone, Mail } from 'lucide-react'
import Contact from "./ContactForm"
import reach from "../../assets/reach-us-banner.png"


function ReachUs() {
  
  return (
    <div className="">
      {/* Hero Section */}
      <motion.div 
        className="relative w-full h-[300px] md:h-[400px] overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <img
          src={reach}
          alt="TSGOC"
          className="w-full h-full object-cover object-left md:object-center"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-center mt-[100px]"
          >
          </motion.div>
        </div>
      </motion.div>

      {/* Contact Info + Form Section */}
      <div className="max-w-7xl mx-auto px-4 md:py-16 grid grid-cols-1 md:grid-cols-2 md:gap-12">
        {/* Contact Information */}
        <div className="">
           {/* Map */}
           <motion.div 
            className="h-[400px] md:block hidden rounded-xl overflow-hidden shadow-lg"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <iframe
            title='map'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1812.8319620617058!2d77.37037691563896!3d28.533478701795083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce61b39222373%3A0xc3a13c69266103be!2sThe%20Shubham%20Group!5e0!3m2!1sen!2sin!4v1738144696786!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </motion.div>

         
        </div>

        {/* Contact Form */}
        <Contact />
        <div className="">
           {/* Map */}
           <motion.div 
            className="h-[400px] block md:hidden  rounded-xl overflow-hidden shadow-lg"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <iframe
            title='map'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1812.8319620617058!2d77.37037691563896!3d28.533478701795083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce61b39222373%3A0xc3a13c69266103be!2sThe%20Shubham%20Group!5e0!3m2!1sen!2sin!4v1738144696786!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </motion.div>

         
        </div>
      </div>
    </div>
  )
}

export default ReachUs






